/* src/fonts/Poppins.css */

@font-face {
    font-family: 'Poppins';
    src: url('./Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
}
  
@font-face {
font-family: 'Poppins';
src: url('./Poppins-Bold.ttf') format('truetype');
font-weight: 700;
}
  
/* Add more styles as needed */